import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/customerService'
    },
    {
        path: '/customerService',
        name: 'CustomerService',
        component: () => import('../views/CustomerService.vue')
    },
    {
        path: '/receivingQRDetail',
        name: 'ReceivingQRDetail',
        component: () => import('../views/customerService/ReceivingQRDetail.vue')
    },
    //roche
    {
        path: '/trainingMaterials',
        name: 'trainingMaterials',
        component: () => import('../views/TrainingMaterials.vue')
    },{
        path: '/policies',
        name: 'policies',
        component: () => import('../views/TrainingMaterials.vue')
    },
    {
        path: '/iframePage',
        name: 'iframePage',
        component: () => import('../views/iframePage.vue')
    },
    {
        path: '/operatingInstructions',
        name: 'OperatingInstructions',
        component: () => import('../views/OperatingInstructions.vue')
    },
    {
        path: '/invoiceInfo',
        name: 'invoiceInfo',
        component: () => import('../views/invoiceInfo.vue')
    },
    {
        path: '/pdfViewer',
        name: 'PDFViewer',
        component: () => import('../views/PDFViewer.vue')
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import('../views/FAQ.vue')
    },
    {
        path: '/faqDetail',
        name: 'FAQDetail',
        component: () => import('../views/FAQDetail.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router