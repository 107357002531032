import { SmartHttp } from 'smart-core-util'

const request = new SmartHttp({
	baseURL: process.env.VUE_APP_GATEWAY,
	// baseURL:'https://a1-gateway-test.roche.com.cn',
	disableLoadingAnimation: true,
	timeout: 20000
}).request;

const httpService = {
	// get公共方法
	Get: (url, params) => {
		return request.get(url, params);
	},
	// TPM
	GetSettings: (params) => {
		return request.post("/api/tpm/cfg/getsettings", params);
	},
    // 数据持久化-保存
    saveCacheData: (params) => {
        return request.post('/api/statestore/redis/create',params);
    },
    // 数据持久化-获取
    queryCacheData: (params) => {
        return request.post('/api/statestore/redis/query',params);
    },
}
export { request, httpService }