import { SmartStorage } from 'smart-core-util'
import { httpService } from '../service/httpService'
import { setCookie } from 'tiny-cookie'

export default class CacheData {
	static install() {
        let url = window.location.search.substring(1);
        let urlParamsObj = {};
        if(url){
            let urlParams = url.split('&');

            urlParams.forEach(item => {
                let temp = item.split('=');
                urlParamsObj[temp[0]] = temp[1];
            })
        } 
		return new Promise(function (resolve) {
            let sessionId = urlParamsObj.sessionId || SmartStorage.get('sessionId')
            if (sessionId) {
                let params = {
                    "sessionId": sessionId
                }
                httpService.queryCacheData(params).then(response => {
                    CacheData.setData(response.content)
                    resolve(response)
                }).catch(error => {
                    resolve(error)
                })
            }else {
                resolve()
            }
        })
    }
    // 设置缓存数据
    static setData(data) {
        setCookie("token", data.token);
        setCookie("token", data.token);
        setCookie("tenant_id", data.tenant_id);
        SmartStorage.set("tenant_code", data.tenant_code);
        SmartStorage.set("token", data.token);
        setCookie("tenant", data.tenant_code);

        if(data.auth) {
            setCookie("token", data.auth.token);
            setCookie("tenant_code", data.auth.tenantCode);
            SmartStorage.set("tenant_code", data.auth.tenantCode);
            SmartStorage.set("token", data.auth.token);
        }
        if(data.data) {
            let objKeyList = Object.keys(data.data);
            objKeyList.map(item => {
                SmartStorage.set(item, data.data[item]);
            });
            // SmartStorage.set("eventData", d.data)
            SmartStorage.set("proposalId", data.data.eventData.proposalId);
        }
        let objKeyList = Object.keys(data);
        objKeyList.map(item => {
            SmartStorage.set(item, data[item]);
        });
    }
}