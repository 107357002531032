'use strict'
// import { getAllCookies } from 'tiny-cookie'
import * as tinyCookie from "tiny-cookie";
import { SmartStorage } from 'smart-core-util'

/**
 * 
 * Webview || iframe communication
 * 
 */
class iJsBridge {
    constructor(options) {
        this.alert = options.alert || window.alert;
    }

    /**
     * 
     * @param {
     * method:alipay.auth
     * postData: object or string
     * callback:function xx() or array
     * } options 
     * call({method:"xxx.yy",postData:{a:1,b:2},callback:function object)
     * call({method:"xxx.yy")
     * call({method:"xxx.yy",postData:)
     * call({method:"xxx.yy",callback)
     * mode = 1,2,3,4
     */
    call(options) {
        try {
            if (!options.method)
                throw "method not allow empty";
            var args = options.method.split('.');
            var clsName = args[0];
            var methodName = args[1];
            var postData = options.postData || {};
            if (typeof (postData) == "object") {
                postData = JSON.stringify(postData);
            }
            var callbackNames = generateCallback(options.callback);
            var returnValue = undefined;
            if (window.webkit) {
                window.webkit.messageHandlers[clsName].postMessage({
                    method: methodName,
                    body: postData,
                    callback: callbackNames
                });
            } else {
                returnValue = window[clsName][methodName]([postData, callbackNames])
            }
            return returnValue;
        } catch (error) {
            console.log("javascript bridge call options:", options);
            console.log("javascript bridge call exception:", error);
            this.alert(`bridge exception:${error.message},please check:${JSON.stringify(options)}`);
            // throw "call failed:" + error.message;
            return undefined;
        }
        function generateCallback(optcallback) {
            if (!optcallback)
                return "";
            var strCallbackNames = [];
            var fns = [];
            if (typeof (optcallback) == "function") {
                fns.push(optcallback);
            } else if (Array.isArray(optcallback)) {
                fns.concat(optcallback);
            }
            //bound xxx
            for (var i = 0; i < fns.length; i++) {
                var fn = fns[i];
                var fnName = fn.name.replace("bound ", "")
                window[fnName] = fn;
                strCallbackNames.push(fnName);
            }
            return strCallbackNames.join(',');
        }
    }

    // 隐藏输入键盘方法
    closeKeyboard() {
        this.call({ method: "SMGeneral.closeKeyboard" });
    }

    /**
     * 
     */
    close() {
        const container = tinyCookie.get("container") || SmartStorage.get('container');
        switch (container) {
            case "browser":
                window.history.back()
                break;
            case "h5":
                window.history.back()
                break;
            case "native":
            default:
                if(window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", '');
                  } else {
                    this.call({ method: "SMGeneral.close" });
                  }
                
                break;
        }


    }
    /**
     * 
     */
    registerBackButton() {

    }
    /**
     * 
     * @param {true/false} able 
     */
    disableRefresh(able) {
        this.call({ method: "SMGeneral.setNativeRefreshable", postData: { able: able } });
    }
    /**
     * if native call failed, use window.postMessage
     * @param {*} options 
     */
    setResult(options) {
        this.call({ method: "SMGeneral.setResultToNative", postData: options.postData });
    }
    goBack() { }
    startApp(options) {
        this.call({ method: "SMGeneral.startApp", postData: { uri: options.uri } });
    }

    async openCamera(options) {
        return this.call({
            method: "SMImage.openCamera",
            callback: options.callback
        })
    }
    async openGallery(options) {
        return this.call({
            method: "SMImage.openGallery",
            callback: options.callback
        })
    }
    /**
     * 
     * @param {
     * postData:{
     *    url:"",
     *    bucketAlias:2bmice
     *    fileName:1527485143570.jpg
     *    fileType:.jpg
     *    thumbnailHeight:70
     *    type:1
     *  }
     * } options 
     */
    async upload(options) {
        if (!options.postData || !options.postData.url)
            throw 'url not allow empty!';
        return this.call({
            method: "SMFileTransfer.upload",
            postData: options.postData,
            callback: options.callback
        })
    }

}

export default iJsBridge;