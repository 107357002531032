
import AppInitialization from "@/plugins/app-initialization.js"
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { SmartRem, SmartStorage } from 'smart-core-util'
import { request, httpService } from './service/httpService';
import iJsBridge from '@/plugins/jsBridge'
import 'smart-core-util/lib/index.css'
SmartRem.install(document);

// //全局引入组件
import 'smartx-ui/lib/index.css'
import smartUI from 'smartx-ui'
Vue.use(smartUI);

// ARMS监控
import "@/plugins/alife-logger.js";

import CacheData from "@/plugins/cache-data.js"
import './assets/public.scss' // 样式入口
import * as tinyCookie from 'tiny-cookie'
Vue.prototype.$iStorage = SmartStorage;
Vue.prototype.iJsBridge = new iJsBridge({
    alert: function (e) {
        store.commit('setValueAlert', e);
    }
});
Vue.prototype.services = httpService;
Vue.prototype.$cookies = tinyCookie;
Vue.prototype.request = request;
Vue.config.productionTip = false

CacheData.install().then(() => {
    AppInitialization.install().then(() => {
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    })
})
