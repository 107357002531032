import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      FAQItem: {}
  },
  mutations: {
    setFAQItem(state, value) {
        state.FAQItem = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
