import { SmartStorage } from 'smart-core-util'
import { httpService } from '../service/httpService'
import * as tinyCookie from 'tiny-cookie'
export default class AppInitialization {
	static install() {
        this.setiDomain();
        this.urlParamToStorage();
		return new Promise(function (resolve) {
            // let url = `https://spkg.smartmice.cn/configuration/iapp-config/${tinyCookie.get('tenant')||SmartStorage.get('tenant')||SmartStorage.get('tenant_code')}/${enviroment}/base.json`
            // let params = {
            //     params: {
            //         rnd: new Date().getTime()
            //     }
            // }
            let tenantId = tinyCookie.get('tenant_id') || SmartStorage.get("tenant_id");
            let params = {
                collection: "cfg-ievent-initialization",
                filter: {
                    tenantId: tenantId
                },
                projection: {
                    _id: 0,
                },
            };

            // httpService.Get(url, params).then(response => {
            httpService.GetSettings(params).then(response => {
                if(response && response.success && response.content) {
                    let config = response.content.config || {}
                    AppInitialization.setTheme(config.themeColors)
                    AppInitialization.setTitle(config.titleTxt)
                } else {
                    AppInitialization.setDefault()
                }

                resolve(response)
            }).catch(error => {
                AppInitialization.setDefault()
                resolve(error)
            })
        })
    }
    static setDefault() {
        AppInitialization.setTheme([
            {"attribute": "--themeColor", "value": "#3875FE"},
            {"attribute": "--clickColor", "value": "#4F7CC4"},
            {"attribute": "--gradualColor", "value": "#4479F1"},
            {"attribute": "--shadowColor", "value": "#3875FECA"},
            {"attribute": "--lineColor", "value": "#3875FE"}
        ])
        AppInitialization.setTitle()
    }
    // 设置皮肤颜色
    static setTheme(colorList) {
        console.log("setTheme: ", colorList)
        colorList.map(item => {
            document.body.style.setProperty(item.attribute, item.value)
        })
    }
    // 设置title
    static setTitle(txt = "会引擎 - 会议平台") {
        document.title = txt
    }

    // url 参数转 storage
    static urlParamToStorage() {
        let url = window.location.search.substring(1);
        if(url){
            let urlParams = url.split('&');
            urlParams.forEach(item => {
                let temp = item.split('=');
                SmartStorage.set(temp[0], temp[1]);
            })
        } 
    }

    static setiDomain() {
        let domain = document.domain;
        const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式 
        let isIP = re.test(domain);
        let isLocalhost = domain == "localhost";
        console.log('document domain:', domain);
        domain = (isIP || isLocalhost) ? domain : domain.substring(domain.indexOf('.') + 1);
        window.idomain = domain;
    }
}